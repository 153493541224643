import { flow } from 'mobx-state-tree';

import http from '@clientCommon/bootstrap/http';

import { API_BASE } from '@clientCommon/constants';
import { MODEL_ROUTE } from '../../constants';

/**
 *
 */
export default flow(function* () {
  const response: { accessToken: string } = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/login`,
    body: {},
  });

  return response.accessToken;
});
