import React from 'react';
import { observer } from 'mobx-react';
import { WithContext as ReactTags, Tag } from 'react-tag-input';

import './tagsInputStyles.scss';

type Props = {
  placeholder?: string;
  label?: string;
  tags: Tag[];
  addTag: (tag: Tag) => void;
  deleteTag: (index: number) => void;
};

const TagsInput = ({ placeholder, label, tags, addTag, deleteTag }: Props) => {
  return (
    <div className="c-TagsInput">
      <ReactTags
        classNames={{
          tags: 'tags',
          selected: 'selected',
          tag: 'tag',
          remove: 'tag-remove-icon',
          tagInput: 'tag-input-container',
          tagInputField: 'tag-input',
        }}
        autofocus={false}
        placeholder={placeholder}
        tags={tags}
        handleAddition={addTag}
        handleDelete={deleteTag}
      />
      {label && <div className="label">{label}</div>}
    </div>
  );
};

export default observer(TagsInput);
