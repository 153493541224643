import { NAME as DATE, FormFieldDate } from './date';
import { NAME as EMAIL, FormFieldEmail } from './email';
import { NAME as STRING, FormFieldString } from './string';

const FORM_FIELD_BY_TYPE = {
  [DATE]: FormFieldDate,
  [EMAIL]: FormFieldEmail,
  [STRING]: FormFieldString,
};

export default FORM_FIELD_BY_TYPE;
