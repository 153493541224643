import React from 'react';
import { observer } from 'mobx-react';

import FileInput from '@clientCommon/components/fileInput';

import CrossIcon from '@clientCommon/assets/icons/x-red.svg';

import { Props as FileInputProps } from '@clientCommon/components/fileInput/types';

import './fileInputPreviewStyles.scss';

type Props = FileInputProps & {
  previewText: string;
  required?: boolean;
  preview?: string | null;
  onDelete?: () => void;
};

const FileInputPreview = ({
  inputId,
  acceptedFileExtensions,
  allowMultipleSelection,
  labelText,
  onChange,
  previewText,
  required = false,
  preview,
  onDelete,
}: Props) => {
  const isRequiredFileEmpty = required && !preview;

  return (
    <div className={`c-FileInputPreview ${isRequiredFileEmpty ? 'c-FileInputPreview-required' : ''}`}>
      <div className="preview-container">
        {preview ? (
          <div className="preview-img-container">
            <img className="preview-img" alt={`${inputId} preview`} src={preview} />
            {onDelete && (
              <img alt={`Delete ${inputId}`} className="delete-img-icon" src={CrossIcon} onClick={() => onDelete()} />
            )}
          </div>
        ) : (
          <div className="preview-text">{previewText}</div>
        )}
      </div>
      <FileInput
        inputId={inputId}
        acceptedFileExtensions={acceptedFileExtensions}
        allowMultipleSelection={allowMultipleSelection}
        labelText={labelText}
        onChange={onChange}
      />
    </div>
  );
};

export default observer(FileInputPreview);
