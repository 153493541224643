import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import PatientCaptureView from './patientCaptureView';

// Stores
import { node as routeStore } from './domain';

// Helpers
import createPatientWithDependencies from './helpers/createPatientWithDependencies';

// Utils
import fileToBase64 from '@common/utils/fileToBase64';

// Types
import { Type as PatientImageType } from '@common/models/patientImages/constants';

const PatientCaptureContainer = () => {
  useEffect(() => {
    routeStore.locals.setInitialState();

    return () => {
      routeStore.locals.setInitialState();
    };
  }, []);

  const onChangeFile = useCallback(async (type: PatientImageType, files: FileList | null) => {
    if (!files || !files.length) {
      return;
    }

    const file: File = Array.from(files)[0];
    const base64File = await fileToBase64(file);
    if (typeof base64File === 'string') {
      routeStore.locals.setFile(type, {
        data: file,
        preview: base64File,
      });
    }
  }, []);

  return <PatientCaptureView
    routeStore={routeStore.locals}
    onChangeFile={onChangeFile}
    createPatientWithDependencies={createPatientWithDependencies}
  />;
};

export default observer(PatientCaptureContainer);
