import { useLayoutEffect, useState, useEffect } from 'react';

export default function useFreshMount() {
  const [isFreshMount, setFreshMount] = useState(false);

  useLayoutEffect(() => {
    setFreshMount(true);
  }, []);

  useEffect(() => {
    if (isFreshMount) {
      setFreshMount(false);
    }
  }, [isFreshMount]);

  return isFreshMount;
}
