import { HttpError, HttpPrivateNamespace } from '@clientCommon/utils/http/types';
import Http from '@clientCommon/utils/http';
import { P } from '@clientCommon/utils/namespace';

/**
 * Take Http Response in errored status, retrieve relevant text and format as an
 * error, then reject.
 * @param p The private namespace getter
 * @param self The class instance
 * @param response The errored Http Response.
 * @throws Throws an HttpError
 */
const handleError = async (p: P<Http, HttpPrivateNamespace>, self: Http, response: Response): Promise<Response> => {
  const err = new Error(response.statusText || `Error response status code: ${response.status}`);

  const text = await response.text();

  const httpError: HttpError = Object.assign(err, {
    text,
    status: response.status,
    res: response,
  });

  let chain: Promise<Response> = Promise.reject(httpError);

  p(self).postResponse.errors.forEach((func) => {
    chain = chain.catch((err) => func(err, response));
  });

  return chain;
};

export default handleError;
