import React, { Component, CSSProperties } from 'react';

import img from './loader.gif';

import './contentScreenStyles.scss';

type Props = {
  active: boolean;
  children?: any;
  className?: string;
  fadeDuration?: number;
  color?: string;
};

class ContentScreen extends Component<Props> {
  timeout?: number;
  mounted: boolean;
  style: CSSProperties;

  static defaultProps = {
    active: false,
  };

  constructor(props: Props) {
    super(props);

    this.timeout = undefined;
    this.mounted = false;
    this.style = {};
  }

  componentWillMount() {
    this.setStyle();
    if (this.props.active) this.handleFade();

    this.timeout = undefined;
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setStyle();
    if (this.props.active !== nextProps.active) this.handleFade();
  }

  render() {
    const fadingOut = !!this.timeout;
    const { active, children } = this.props;
    return (
      !!(active || fadingOut) && (
        <div className={`c-ContentScreen ${this.props.className || ''}`}>
          {
            <div style={this.style} className={`spinner ${active ? 'active' : ''}`}>
              <img alt="Loading..." style={{ opacity: ~~active * 0.5 }} src={img} />
            </div>
          }
          {children || false}
        </div>
      )
    );
  }

  handleFade() {
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.timeout = undefined;
      this.mounted && this.forceUpdate();
    }, this.props.fadeDuration);
  }

  setStyle() {
    if (!this.props.fadeDuration) return {};
    const transition = this.props.fadeDuration ? `opacity ${this.props.fadeDuration}ms` : undefined;
    this.style = {
      transition: transition,
      WebkitTransition: transition,
      backgroundColor: this.props.color || '#FFFFFF',
    };
  }
}

export default ContentScreen;
