import BPromise from 'bluebird';
import { isString as _isString } from 'lodash';

import { HttpPrivateNamespace, HttpPayload, HttpResponse } from '@clientCommon/utils/http/types';
import { P } from '@clientCommon/utils/namespace';
import Http from '@clientCommon/utils/http';

// Private methods.
import _handleError from '../_handleError';
import _handleSuccess from '../_handleSuccess';

/**
 * Runs preRequests, executes http call, then runs postResponses.
 * @param p The private namespace getter
 * @param self The class instance
 * @param payload The Http Payload
 * @returns The http response body
 */
const executeHttp = async <DataType>(
  p: P<Http, HttpPrivateNamespace>,
  self: Http,
  payload: HttpPayload
): Promise<HttpResponse<DataType> | DataType | ArrayBuffer> => {
  payload = payload || {};
  payload.headers = payload.headers || {};
  if (!(payload.body instanceof FormData) && !payload.headers['Content-Type'])
    payload.headers['Content-Type'] = 'application/json';
  if (payload.body && !_isString(payload.body) && !(payload.body instanceof FormData))
    payload.body = JSON.stringify(payload.body);

  await BPromise.mapSeries(p(self).preRequests, async (fn) => {
    payload = (await fn(payload)) || payload;
  });

  let response = await fetch(payload.url, payload);

  const result =
    response.status >= 400 ? await _handleError(p, self, response) : await _handleSuccess(p, self, response);

  if (response.headers.get('Content-Type') === 'image/png') return response.arrayBuffer();
  const json: DataType = await result.json();

  return payload.fullResponse ? { ...result, data: json } : json;
};

export default executeHttp;
