import React, { useCallback, useEffect, useMemo, ChangeEvent } from 'react';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

// Types
import { FormFieldProps, FormFieldByTypeProps } from './types';

// Components
import FormFieldByType from './byType';
import { DebounceSettings } from 'lodash';

// Helpers
import isValueValid from './helpers/isValueValid';

// Styles
import './formFieldStyles.scss';

function FormField<T>(props: FormFieldProps<T> & { debounce?: DebounceSettings & { wait: number } }) {
  const { schema, label, debounce: debounceSettings, onChange, onValid, formatter, required = false } = props;

  const typeIdentifier = schema.format || schema.type;
  if (!typeIdentifier) {
    throw new Error(`FormField cannot render, schema must include either a format or a type.`);
  }
  const FormFieldByTypeComponent: React.FunctionComponent<FormFieldByTypeProps<T>> = _get(
    FormFieldByType,
    typeIdentifier
  );
  if (!FormFieldByTypeComponent) {
    throw new Error(
      `FormField cannot render, provided type identifier "${typeIdentifier}" not associated with any known FormField type.`
    );
  }

  const isProvidedValueValid = useMemo(() => {
    if (props.value) {
      return isValueValid(props.value, schema);
    } else {
      return true;
    }
  }, [props.value, schema]);

  const debounced = useCallback(
    _debounce(
      (value: T) => {
        const meta = {
          label,
          schema,
          value,
        };

        const formatted = formatter?.beforeValidate ? formatter?.beforeValidate(meta) : value;

        if (onValid && isValueValid(formatted, schema)) {
          onValid({ ...meta, value: formatted });
        }
      },
      _get(debounceSettings, 'wait', 0),
      debounceSettings
    ),
    [debounceSettings, label, schema, formatter, onValid]
  );

  // On change listener for FormFieldByType child.
  const localOnChange = useCallback(
    ({ value }, evt: ChangeEvent<HTMLInputElement>) => {
      const meta = {
        label,
        schema,
        value,
      };

      const formatted = formatter?.beforeDebounce ? formatter?.beforeDebounce(meta, evt) : value;

      debounced(formatted);

      // Call props listener.
      if (onChange) {
        onChange({ ...meta, value: formatted }, evt);
      }
    },
    [label, schema, formatter, debounced, onChange]
  );

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, []);

  const input = React.createElement(FormFieldByTypeComponent, {
    ...props,
    required,
    isProvidedValueValid,
    onChange: localOnChange,
  });

  return (
    <div className="c-FormField">
      {input}
      {label ? <label className="input-label">{label}</label> : false}
    </div>
  );
}

export default FormField;
