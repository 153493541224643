import Http from '@clientCommon/utils/http';
import { API_BASE } from '@clientCommon/constants/index';

import store from '@clientCommon/bootstrap/store';
import persistentStorage from '@clientCommon/bootstrap/persistentStorage';
import { HttpPayload } from '@clientCommon/utils/http/types';

const http = new Http();

export default http;

// // sets token to requests if token exists and matches api base
http.addPreRequest((_payload) => {
  const { activeToken: token } = store.models.users;

  const payload: Partial<HttpPayload> = _payload || {};
  if (payload.url && payload.url.match(API_BASE) && !!token) {
    payload.headers = {
      Authorization: `Bearer ${token}`,
      ...(payload.headers || {}),
    };
    payload.mode = 'cors';
  }
  return payload as HttpPayload;
});

http.addPostResponseError((err) => {
  console.log('HTTP Post Response Error', err.message, 'Namo', err.name, 'Texto', err.text, err.status);
  if (
    err.text.match('Unauthorized') ||
    err.text.match('The access token provided is invalid') ||
    err.text.match('No authenticated user found associated with this request')
  ) {
    persistentStorage.remove('activeToken');
    console.log('Auth error', err);
    // window.location.reload();
  }
  return Promise.reject(err);
});
