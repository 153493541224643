import { flow } from 'mobx-state-tree';

import http from '@clientCommon/bootstrap/http';

import { API_BASE } from '@clientCommon/constants';
import { MODEL_ROUTE } from '../../constants';

import { Patient, PatientCreationBody } from '@common/models/patients/types';

type PatientCreationBodyClientSide = Omit<PatientCreationBody, 'externalPharmacyId'> & {
  externalPharmacyId?: string | null;
  healthcareSiteId?: string;
};

type Config = {
  identifyingAccessToken?: string;
};

const createPatient = flow(function* (patient: PatientCreationBodyClientSide, { identifyingAccessToken }: Config = {}) {
  const headers: { Identifier?: string } = {};
  if (identifyingAccessToken) {
    headers.Identifier = identifyingAccessToken;
  }

  const response: Patient = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}`,
    body: patient,
    headers,
  });

  return response;
});

export default createPatient;
