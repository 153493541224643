import React from 'react';
import { observer } from 'mobx-react';

// Types
import { HealthcareSite } from '@common/models/healthcareSites/types';

// Components
import ContentGate from '@clientCommon/components/contentGate/index.web.p';
import H1 from '@clientCommon/components/composition/h1';

// Children
import NoHealthcareSite from './children/noHealthcareSite';
import PatientCapture from './children/patientCapture';

// Styles
import './rootViewStyles.scss';

type Props = {
  isLoadingIdentifyingAccessToken: boolean;
  healthcareSite: HealthcareSite | null;
};

function RootView({
  isLoadingIdentifyingAccessToken,
  healthcareSite,
}: Props) {
  return <ContentGate
    className='c-RootView'
    active={isLoadingIdentifyingAccessToken}
    fadeDuration={2000}
  >
  {
    !healthcareSite
    ? <NoHealthcareSite />
    : <div className='content-container'>
      <H1 className='header'>
        New Patient Form
      </H1>
      <PatientCapture />
    </div>
  }
    
  </ContentGate>;
}

export default observer(RootView);
