import { types } from 'mobx-state-tree';

import { type as auth } from './auth';
import { type as identifyingAccessTokens } from './identifyingAccessTokens';

export default types.optional(
  types.model({
    auth,
    identifyingAccessTokens,
  }),
  {},
);

