import { types } from 'mobx-state-tree';


export const type = types.optional(
  types.model({
    hasCheckedLocalStorage: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setTyped: <K extends keyof typeof self>(prop: K, value: typeof self[K]) => {
      self[prop] = value;
    },
  }))
, {});

// export const node = type.create({});  
