import React from 'react';
import { observer } from 'mobx-react';

import { Props } from './types';

import './fileInputStyles.scss';

const FileInput = ({ inputId, acceptedFileExtensions, allowMultipleSelection = false, labelText, onChange }: Props) => (
  <div className="c-FileInput">
    <input
      id={inputId}
      type="file"
      className="input"
      accept={acceptedFileExtensions}
      multiple={allowMultipleSelection}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.files);
        }
      }}
    />
    <label className="label" htmlFor={inputId}>
      {labelText || 'Select File'}
    </label>
  </div>
);

export default observer(FileInput);
