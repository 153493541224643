// External
import { types } from 'mobx-state-tree';

// Locals
// import models from './models';
import {
  type as routesType,
  node as routesNode,
} from './routes';
// import router from './global/router';
import global from './global';

const store = types.optional(types.model({
  /** The application store.models. */
  // models: types.optional(types.model('ApplicationModels', models), {} as any),
  /** The application route domains. */
  routes: routesType,
  /**  */
  router: types.frozen(),
  global,
}), {});

const created = store.create({
  routes: routesNode,
  // global: {},
  // router,
  // machine: machineNode,
});

console.log('Bootstrapped store', created);

export default created;


export type StoreType = typeof created;
// export type ModelsType = typeof created['models'];
export type RoutesType = typeof created['routes'];
