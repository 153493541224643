// Stores
import common from '@clientCommon/bootstrap/store';
import store from 'bootstrap/store';
import { node as routeStore } from '../domain';

// Constants
import { ResolutionText, RESOLUTION_TEXT_VISABILITY_TIMEOUT } from '../constants';
import { SCHEMA as PATIENT_BACKEND_SCHEMA } from '@common/models/patients/constants';

// Utils
import extractDigitsFromString from '@clientCommon/utils/extractDigitsFromString';
import isObjectValidBySchema from '@common/utils/isObjectValidBySchema';

const createPatientWithDependencies = async () => {
  const identifyingAccessToken = store.global.identifyingAccessTokens.identifyingAccessTokenId;
  const { healthcareSite } = store.global.identifyingAccessTokens;
  if (!identifyingAccessToken || !healthcareSite) {
    return;
  }

  routeStore.locals.setIsCreatingPatient(true);

  try {
    const allergies = routeStore.locals.allergies;
    
    const patient = routeStore.locals.patient;
    const patientBackendAdjusted = {
      ...patient,
      phoneNumber: patient.phoneNumber ? extractDigitsFromString(patient.phoneNumber) : null,
    };
    const isPatientValidForBackend = isObjectValidBySchema(patientBackendAdjusted, PATIENT_BACKEND_SCHEMA);
    if (!isPatientValidForBackend) {
      return Promise.reject('Patient cannot be submitted, because it does not satisfy the backend schema.');
    }

    const patientCreationResponseBody = await common.models.patients.async.create({
      firstName: patientBackendAdjusted.firstName as string,
      lastName: patientBackendAdjusted.lastName as string,
      DOB: patientBackendAdjusted.DOB,
      email: patientBackendAdjusted.email,
      phoneNumber: patientBackendAdjusted.phoneNumber,
      allergies,
    }, { identifyingAccessToken });
    const patientId = patientCreationResponseBody.id;
  
    const associateHealthcareSitePromise = common.models.patients.async.associateHealthcareSite({
      patientId,
      healthcareSiteId: healthcareSite.id,
    }, { identifyingAccessToken });
    const createPatientImagePromises: Promise<any>[] = [];
    routeStore.locals.files.forEach(({ type, file }) => {
      if (file.data) {
        const createPatientImagePromise = common.models.patientImages.async.create(patientId, { type, file: file.data }, { identifyingAccessToken });
        createPatientImagePromises.push(createPatientImagePromise);
      }
    });
  
    await Promise.all([
      associateHealthcareSitePromise,
      ...createPatientImagePromises
    ]);

    routeStore.locals.setIsCreatingPatient(false);
    routeStore.locals.setResolutionText(ResolutionText.Success);
    setTimeout(() => {
      routeStore.locals.setInitialState();
    }, RESOLUTION_TEXT_VISABILITY_TIMEOUT);
    
    return patientCreationResponseBody;
  } catch (err) {
    routeStore.locals.setResolutionText(ResolutionText.Error);
    setTimeout(() => {
      routeStore.locals.setResolutionText(null);
    }, RESOLUTION_TEXT_VISABILITY_TIMEOUT);
  }
};

export default createPatientWithDependencies;
