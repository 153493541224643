import React from 'react';

import H1 from '@clientCommon/components/composition/h1';

import './noHealthcareSiteStyles.scss';

function NoHealthcareSite() {
  return <div className='c-NoHealthcareSite'>
    <H1 className='no-healthcare-site-text'>
      Unable to Find or Load Healthcare Site
    </H1>
  </div>;
}

export default NoHealthcareSite;
