import constructModel from './constructModel';

import * as healthcareSites from '@clientCommon/models/healthcareSites';
import * as identifyingAccessTokens from '@clientCommon/models/identifyingAccessTokens';
import * as patientImages from '@clientCommon/models/patientImages';
import * as patients from '@clientCommon/models/patients';
import * as users from '@clientCommon/models/users';

const models = {
  healthcareSites: constructModel(healthcareSites),
  identifyingAccessTokens: constructModel(identifyingAccessTokens),
  patientImages: constructModel(patientImages),
  patients: constructModel(patients),
  users: constructModel(users),
};

export default models;

export type Models = typeof models;
