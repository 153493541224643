import { merge as _merge } from 'lodash';

import constructCommonStyles from './common';

import { WebStyle } from '@clientCommon/styles/web';
import { ContentGateStyleParams } from './types';

const constructWebStyles = (params: ContentGateStyleParams): WebStyle => {
  const webStyles: WebStyle = {};
  const commonStyles = constructCommonStyles(params);

  return _merge(commonStyles, webStyles);
};

export default constructWebStyles;
