import 'whatwg-fetch'; // Polyfill for fetch
import param from 'jquery-param';

// utils
import namespace, { P } from '@clientCommon/utils/namespace';

// types
import {
  HttpPrivateNamespace,
  HttpPayload,
  HttpMethod,
  PreRequestFn,
  PostResponseSuccessFn,
  PostResponseErrorFn,
} from './types';
import { Optionalize, Require } from '@clientCommon/types';

// Private Methods
import _executeHttp from './methods/private/_executeHttp';

const p: P<Http, HttpPrivateNamespace> = namespace();

/**
 *
 */
export default class Http {
  constructor() {
    p(this).preRequests = new Set();
    p(this).postResponse = {
      successes: new Set(),
      errors: new Set(),
    };
  }

  addPreRequest(func: PreRequestFn) {
    p(this).preRequests.add(func);
  }

  addPostResponse(func: PostResponseSuccessFn) {
    p(this).postResponse.successes.add(func);
  }

  addPostResponseError(func: PostResponseErrorFn) {
    p(this).postResponse.errors.add(func);
  }

  generateQueryParams(req: Optionalize<HttpPayload, 'method'>): string {
    if (Object.keys(req.query || {}).length) {
      req.url = (req.url || '') + `?${param(req.query)}`;
    }
    return req.url || '';
  }

  get<ReturnType>(req: Optionalize<HttpPayload, 'method'>) {
    if (!req.url) return Promise.reject(new Error(`Cannot call Http.get, no url string provided.`));
    req.method = HttpMethod.GET;
    req.url = this.generateQueryParams(req);

    return _executeHttp<ReturnType>(p, this, req as HttpPayload);
  }

  post<ReturnType>(req: Require<Optionalize<HttpPayload, 'method'>, 'body'>) {
    req.method = HttpMethod.POST;
    return _executeHttp<ReturnType>(p, this, req as HttpPayload);
  }

  put<ReturnType>(req: Require<Optionalize<HttpPayload, 'method'>, 'body'>) {
    req.method = HttpMethod.PUT;
    return _executeHttp<ReturnType>(p, this, req as HttpPayload);
  }

  delete(req: Optionalize<HttpPayload, 'method'>) {
    req.method = HttpMethod.DELETE;
    return _executeHttp(p, this, req as HttpPayload);
  }
}
