import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import store from 'bootstrap/store';
import common from '@clientCommon/bootstrap/store';

import RootView from './rootView';


function RootContainer() {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const identifyingAccessTokenId = query.get('identifyingAccessToken');
    if (identifyingAccessTokenId) {
      store.global.identifyingAccessTokens.setTyped('identifyingAccessTokenId', identifyingAccessTokenId);
    };
  }, [window.location.search]);

  useEffect(() => {
    const { identifyingAccessTokenId } = store.global.identifyingAccessTokens;
    if (!identifyingAccessTokenId) {
      return;
    }

    (async () => {
      try {
        const identifyingAccessToken = await common.models.identifyingAccessTokens.async.getByIdDecorated(identifyingAccessTokenId);
        store.global.identifyingAccessTokens.setTyped('identifyingAccessToken', identifyingAccessToken);
      } catch(err) {}
    })();
  }, [store.global.identifyingAccessTokens.identifyingAccessTokenId]);


  return <RootView
    isLoadingIdentifyingAccessToken={Boolean(common.models.identifyingAccessTokens.async.busy.getByIdDecorated)}
    healthcareSite={store.global.identifyingAccessTokens.healthcareSite}
  />;
}

export default observer(RootContainer);
