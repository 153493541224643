const storage = {
  get: async (key: string) => {
    return localStorage.getItem(key);
  },
  getJSON: async (key: string) => {
    const result = await storage.get(key);
    return result ? JSON.parse(result) : result;
  },
  set: async (key: string, value: string) => {
    return localStorage.setItem(key, value);
  },
  setJSON: async (key: string, value: any) => {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  remove: async (key: string): Promise<void> => {
    return localStorage.removeItem(key);
  },
};

export default storage;
