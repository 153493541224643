/**
 * @module models/users/props
 * @see module:models/users
 */

import { types } from 'mobx-state-tree';

export const activeToken = types.maybeNull(types.string);

export const test = types.optional(types.string, 'I EXIST');
