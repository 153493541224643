export enum Type {
  InsuranceFront = 'INSURANCE_FRONT',
  InsuranceBack = 'INSURANCE_BACK',
  Signature = 'SIGNATURE',
  PatientPicture = 'PATIENT_PICTURE',
  FrontDriverLicense = 'FRONT_DRIVER_LICENSE',
  BackDriverLicense = 'BACK_DRIVER_LICENSE',
}

export const TYPES_LIST = Object.values(Type);
