import { HttpPrivateNamespace } from '@clientCommon/utils/http/types';
import Http from '@clientCommon/utils/http';
import { P } from '@clientCommon/utils/namespace';

/**
 * Take Http Response and run through postResponse handlers.
 * @param p The private namespace getter
 * @param self The class instance
 * @param response The errored Http Response.
 * @throws Throws an HttpError
 */
const handleSuccess = async (p: P<Http, HttpPrivateNamespace>, self: Http, response: Response): Promise<Response> => {
  let chain: Promise<Response> = Promise.resolve(response);

  p(self).postResponse.successes.forEach((func) => {
    chain = chain.then((result) => func(result));
  });

  return chain;
};

export default handleSuccess;
