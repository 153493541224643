import { types } from 'mobx-state-tree';

import locals from './locals';
import {
  type as childrenTypes,
  node as childrenNode
} from './children';

export const type = types.optional(types.model({
  locals,
  children: childrenTypes,
}), {});

export const node = type.create({
  children: childrenNode
});

export type StoreNode = typeof node;
export type ChildrenStore = typeof type;
export type Locals = typeof locals;
