import { PROPERTY as BACKEND_PROPERTY, SCHEMA as BACKEND_SCHEMA } from '@common/models/patients/constants';

export const MODEL_ROUTE = '/patients';

export const SCHEMA = {
  ...BACKEND_SCHEMA,
  properties: {
    ...BACKEND_SCHEMA.properties,
    [BACKEND_PROPERTY.PHONE_NUMBER]: {
      type: 'string',
      pattern: '^[(][0-9]{3}[)][0-9]{3}-[0-9]{4}$', // (128)932-8293
    },
  },
  required: [
    BACKEND_PROPERTY.FIRST_NAME,
    BACKEND_PROPERTY.LAST_NAME,
    BACKEND_PROPERTY.DOB,
    BACKEND_PROPERTY.PHONE_NUMBER,
    BACKEND_PROPERTY.EMAIL,
  ] as string[],
} as const;

export const PROPERTY = BACKEND_PROPERTY;
