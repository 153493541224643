import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

// Constants
import { ResolutionText, RESOLUTION_TEXTS } from '../constants';
import { PROPERTY, SCHEMA } from '@clientCommon/models/patients/constants';
import { TYPES_LIST, Type } from '@common/models/patientImages/constants';

// Utils
import isObjectValidBySchema from '@common/utils/isObjectValidBySchema';

// Types
import { Allergy } from '@common/models/patients/types';

type FileContents = {
  data: File | undefined;
  preview: string | undefined;
};

export default types.optional(types.model({
  patient: types.optional(types.model({
    [PROPERTY.FIRST_NAME]: types.maybeNull(types.string),
    [PROPERTY.LAST_NAME]: types.maybeNull(types.string),
    [PROPERTY.DOB]: types.maybeNull(types.string),
    [PROPERTY.PHONE_NUMBER]: types.maybeNull(types.string),
    [PROPERTY.EMAIL]: types.maybeNull(types.string),
  }), {}),
  files: types.optional(types.array(types.model({
    type: types.enumeration('Type', TYPES_LIST),
    file: types.model({
      data: types.maybe(types.frozen<File>()),
      preview: types.maybe(types.string),
    }),
  })), []),
  allergies: types.optional(types.array(types.frozen<Allergy>()), []),
  isReCaptchaSubmitted: types.optional(types.boolean, false),
  isCreatingPatient: types.optional(types.boolean, false),
  resolutionText: types.maybeNull(types.enumeration(RESOLUTION_TEXTS)),
})
.actions(self => ({
  setValue: (path: string, value: any) => {
    _set(self, path, value);
  },
  setPatientValue: <K extends keyof typeof self['patient']>(property: K, value: typeof self['patient'][K]) => {
    self.patient[property] = value;
  },
  setIsReCaptchaSubmitted: (value: boolean) => self.isReCaptchaSubmitted = value,
  setIsCreatingPatient: (value: boolean) => self.isCreatingPatient = value,
  setResolutionText: (text: ResolutionText | null) => self.resolutionText = text,
  addAllergy: (allergy: { id: string, text: string }) => self.allergies.push({name: allergy.text}),
  deleteAllergy: (index: number) => self.allergies.splice(index, 1),
  setFile: (fileType: Type, data: FileContents) => {
    const existingFile = self.files.find(({ type }) => type === fileType);
    if (existingFile) {
      existingFile.file = data;
    } else {
      self.files.push({
        type: fileType,
        file: data,
      });
    }

    self.files.map((file) => console.log(file));
  },
  deleteFile: (fileType: Type) => {
    const existingFile = self.files.find(({ type }) => type === fileType);
    if (existingFile) {
      self.files.remove(existingFile);
    }
  },
}))
.actions(self => ({
  clearPatientValues: () => {
    self.setPatientValue(PROPERTY.FIRST_NAME, null);
    self.setPatientValue(PROPERTY.LAST_NAME, null);
    self.setPatientValue(PROPERTY.DOB, null);
    self.setPatientValue(PROPERTY.PHONE_NUMBER, null);
    self.setPatientValue(PROPERTY.EMAIL, null);
  },
}))
.actions(self => ({
  setInitialState: () => {
    self.clearPatientValues();
    self.allergies = [] as any;
    self.files = [] as any;
    self.isReCaptchaSubmitted = false;
    self.isCreatingPatient = false;
    self.resolutionText = null;
  },
}))
.views(self => ({
  get allergyTags() {
    return self.allergies.map(({ name }) => ({ id: name, text: name }))
  },
  get fileByType() {
    const byType = {} as { [type in Type]: FileContents };
    self.files.forEach(({ type, file }) => {
      byType[type] = file;
    });

    return byType;
  },
}))
.views(self => ({
  get canBeSubmitted() {
    return (
      isObjectValidBySchema(self.patient, SCHEMA) &&
      self.fileByType[Type.InsuranceFront]?.data &&
      self.fileByType[Type.InsuranceBack]?.data &&
      self.isReCaptchaSubmitted
    );
  },
})), {});
