import { JSONSchema7 } from 'json-schema';

export const PROPERTY = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DOB: 'DOB',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
} as const;

export const SCHEMA = {
  type: 'object',
  properties: {
    [PROPERTY.FIRST_NAME]: {
      type: 'string',
      minLength: 1,
    },
    [PROPERTY.LAST_NAME]: {
      type: 'string',
      minLength: 1,
    },
    [PROPERTY.DOB]: {
      type: 'string',
      pattern: '^[0-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$',
      format: 'date',
      minLength: 10,
      maxLength: 10,
    },
    [PROPERTY.PHONE_NUMBER]: {
      type: 'string',
      pattern: '^([0-9]|x)+$',
    },
    [PROPERTY.EMAIL]: {
      type: 'string',
      pattern: '^\\S+@\\S+\\.\\S+$',
      format: 'email',
      minLength: 6,
    },
  },
  required: [PROPERTY.FIRST_NAME, PROPERTY.LAST_NAME] as string[],
} as const;

// Sanity Check
const schema: JSONSchema7 = SCHEMA;

export const GENDER = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  INDETERMINATE: 'INDETERMINATE',
} as const;

export const GENDER_LIST = Object.values(GENDER);
