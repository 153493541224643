import ReCAPTCHA from 'react-google-recaptcha';
import { observer } from 'mobx-react';

// Components
import ContentScreen from '@clientCommon/components/contentScreen';
import FormField from '@clientCommon/components/formField';
import FileInputPreview from '@clientCommon/components/fileInputPreview';
// import Expandable from '@clientCommon/components/expandable';
import TagsInput from '@clientCommon/components/tagsInput';

// Constants
import { ResolutionText } from './constants';
import { ENV_RECAPTCHA_PUBLIC_KEY } from 'constants/environmentVariables';
import { PROPERTY, SCHEMA } from '@clientCommon/models/patients/constants';
import { Type as PatientImageType } from '@common/models/patientImages/constants';

// Utils
import formatPhone from '@common/utils/format/phoneNumber/forInput';

// Types
import { StoreNode } from './domain';

// Styles
import './patientCaptureViewStyles.scss';

type Props = {
  routeStore: StoreNode['locals'];
  onChangeFile: (type: PatientImageType, files: FileList | null) => {};
  createPatientWithDependencies: () => void;
};

function PatientCaptureView({
  routeStore,
  onChangeFile,
  createPatientWithDependencies,
}: Props) {
  return <div className='c-PatientCaptureView'>
    <ContentScreen active={routeStore.isCreatingPatient} />
    <FormField
      label='First Name'
      value={routeStore.patient[PROPERTY.FIRST_NAME]}
      schema={SCHEMA.properties[PROPERTY.FIRST_NAME]}
      onChange={({ value }) => routeStore.setPatientValue(PROPERTY.FIRST_NAME, value)}
      required={true}
    />
    <FormField
      label='Last Name'
      value={routeStore.patient[PROPERTY.LAST_NAME]}
      schema={SCHEMA.properties[PROPERTY.LAST_NAME]}
      onChange={({ value }) => routeStore.setPatientValue(PROPERTY.LAST_NAME, value)}
      required={true}
    />
    <FormField
      label='Date Of Birth'
      value={routeStore.patient[PROPERTY.DOB]}
      schema={SCHEMA.properties[PROPERTY.DOB]}
      onChange={({ value }) => routeStore.setPatientValue(PROPERTY.DOB, value)}
      required={true}
    />
    <FormField
      label='Phone Number'
      value={routeStore.patient[PROPERTY.PHONE_NUMBER]}
      schema={SCHEMA.properties[PROPERTY.PHONE_NUMBER]}
      formatter={{ beforeDebounce: ({ value }, evt) => formatPhone(value || '', routeStore.patient[PROPERTY.PHONE_NUMBER]) }}
      onChange={({ value }) => routeStore.setPatientValue(PROPERTY.PHONE_NUMBER, value)}
      required={true}
    />
    <FormField
      label='Email'
      value={routeStore.patient[PROPERTY.EMAIL]}
      schema={SCHEMA.properties[PROPERTY.EMAIL]}
      onChange={({ value }) => routeStore.setPatientValue(PROPERTY.EMAIL, value)}
      required={true}
    />
    <TagsInput
      placeholder='Type an allergy and click "return"'
      label='Allergies'
      tags={routeStore.allergyTags}
      addTag={routeStore.addAllergy}
      deleteTag={routeStore.deleteAllergy}
    />
    <div className='files-container'>
      <FileInputPreview
        inputId='insurance-front-side'
        onChange={(files) => onChangeFile(PatientImageType.InsuranceFront, files)}
        previewText='Insurance Front Side'
        required={true}
        preview={routeStore.fileByType[PatientImageType.InsuranceFront]?.preview}
        onDelete={() => routeStore.deleteFile(PatientImageType.InsuranceFront)}
      />
      <FileInputPreview
        inputId='insurance-back-side'
        onChange={(files) => onChangeFile(PatientImageType.InsuranceBack, files)}
        previewText='Insurance Back Side'
        required={true}
        preview={routeStore.fileByType[PatientImageType.InsuranceBack]?.preview}
        onDelete={() => routeStore.deleteFile(PatientImageType.InsuranceBack)}
      />
      {/* <Expandable text='Identification Card'>
        <FileInputPreview
          inputId='identification-card-front-side'
          onChange={(files) => onChangeFile(PatientImageType.FrontDriverLicense, files)}
          previewText="Identification's Card Front Side"
          required={false}
          preview={routeStore.fileByType[PatientImageType.FrontDriverLicense]?.preview}
          onDelete={() => routeStore.deleteFile(PatientImageType.FrontDriverLicense)}
        />
        <FileInputPreview
          inputId='identification-card-back-side'
          onChange={(files) => onChangeFile(PatientImageType.BackDriverLicense, files)}
          previewText="Identification's Card Back Side"
          required={false}
          preview={routeStore.fileByType[PatientImageType.BackDriverLicense]?.preview}
          onDelete={() => routeStore.deleteFile(PatientImageType.BackDriverLicense)}
        />
      </Expandable> */}
    </div>
    <ReCAPTCHA
      sitekey={ENV_RECAPTCHA_PUBLIC_KEY}
      onChange={() => routeStore.setIsReCaptchaSubmitted(true)}
    />
    <div className='submit-container'>
      <button
        className='submit-btn'
        disabled={!routeStore.canBeSubmitted}
        onClick={() => createPatientWithDependencies()}
      >
        Submit
      </button>
      {
        routeStore.resolutionText &&
        <div className={routeStore.resolutionText === ResolutionText.Success ? 'succeess-text' : 'error-text'}>
          {
            routeStore.resolutionText
          }
        </div>
      }
    </div>
  </div>;
}

export default observer(PatientCaptureView);
