export type HttpPrivateNamespace = {
  preRequests: Set<PreRequestFn>;
  postResponse: {
    successes: Set<PostResponseSuccessFn>;
    errors: Set<PostResponseErrorFn>;
  };
};

export type PreRequestFn = (payload?: HttpPayload) => HttpPayload | PromiseLike<HttpPayload>;

export type PostResponseSuccessFn = <DataType>(response: Response) => PromiseLike<HttpResponse<DataType>>;
export type PostResponseErrorFn = (error: HttpError, response: Response) => PromiseLike<Response>;

export enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type HttpPayloadData = {
  body?: HttpBody;
  query?: HttpQuery;
  params?: HttpParams;
  headers?: HttpHeaders;
};

export type HttpPayload = HttpPayloadData & {
  method: HttpMethod;
  url: string;
  /** Whether or not the full response should be included, defaults false. */
  fullResponse?: boolean;
  mode?: 'cors';
};

export type HttpResponse<DataType> = Response & {
  data: DataType;
};

export type HttpError = {
  status: number;
  text: string;
  res: Response;
} & Error;

export type HttpBody = any;
export type HttpQuery = any;
export type HttpHeaders = any;
export type HttpParams = any;
