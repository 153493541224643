import React, { ChangeEvent, useCallback } from 'react';

// Types
import { FormFieldByTypeProps } from '@clientCommon/components/formField/types';

export const NAME = 'string';

export function FormFieldString({ value, onChange, required, isProvidedValueValid }: FormFieldByTypeProps<string>) {
  const localOnChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) =>
      onChange(
        {
          value: evt.target.value,
        },
        evt
      ),
    [onChange]
  );

  const inputValue = String(value || '');
  const isRequiredAndEmpty = !inputValue && required;

  return (
    <input
      className={`input ${isRequiredAndEmpty || !isProvidedValueValid ? 'input-required' : ''}`}
      type="text"
      value={inputValue}
      onChange={localOnChange}
    />
  );
}
