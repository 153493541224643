import { flow } from 'mobx-state-tree';

import http from '@clientCommon/bootstrap/http';

import { API_BASE } from '@clientCommon/constants';
import { MODEL_ROUTE } from '@clientCommon/models/patientImages/constants';

import { Type as PatientImageType } from '@common/models/patientImages/constants';
import { PatientImage } from '@common/models/patientImages/types';

type Body = {
  type: PatientImageType;
  file: File;
  healthcareSiteId?: string;
};
type Config = {
  identifyingAccessToken?: string;
};

const createPatientImage = flow(function* (
  patientId: string,
  { type, file, healthcareSiteId }: Body,
  { identifyingAccessToken }: Config = {}
) {
  const patientImageFormData = new FormData();
  patientImageFormData.append('type', type);
  patientImageFormData.append('file', file);
  if (healthcareSiteId) {
    patientImageFormData.append('healthcareSiteId', healthcareSiteId);
  }

  const headers: { Identifier?: string } = {};
  if (identifyingAccessToken) {
    headers.Identifier = identifyingAccessToken;
  }

  const response: PatientImage = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/patients/${patientId}`,
    body: patientImageFormData,
    headers,
  });

  return response;
});

export default createPatientImage;
