const RE = /^(?<areaCode>[0-9]{0,3})(?<first>[0-9]{0,3})(?<second>[0-9]{0,4})/;

const formatPhoneNumberForInput = (input: string = '', previousValue?: string | null) => {
  // Handle cases of when backspace was pressed in order to remove an extra character added, i.e. ) or -, which
  // gets us to a state indistinguishable from having gone forward by entering a number. By comparing to previous
  // state in order to determine if a deleting occurred, we then know whether an extra character was meant to
  // have been deleted.
  if (previousValue) {
    // (999) went to (999, and should become (99
    if (input.match(/\([0-9]{3}$/) && previousValue.match(/\([0-9]{3}\)$/)) input = input.slice(0, input.length - 1);
    // (999)999- went to (999)999, and should become (999)99
    else if (input.match(/\([0-9]{3}\)[0-9]{3}$/) && previousValue.match(/\([0-9]{3}\)[0-9]{3}\-$/))
      input = input.slice(0, input.length - 1);
  }

  const stripped = input.replace(/[^0-9]/g, '');
  const match = stripped.match(RE);
  const areaCode = match?.groups?.areaCode;
  const first = match?.groups?.first;
  const second = match?.groups?.second;
  let output = areaCode ? `(${areaCode}${areaCode?.length === 3 ? ')' : ''}` : '';
  if (first) output += `${first}${first?.length === 3 ? '-' : ''}`;
  if (second) output += `${second}`;
  return output;
};

export default formatPhoneNumberForInput;
