import { types } from 'mobx-state-tree';

// Types
import { IdentifyingAccessTokenDecorated } from '@common/models/identifyingAccessTokens/types';


export const type = types.optional(
  types.model({
    identifyingAccessTokenId: types.maybeNull(types.string),
    identifyingAccessToken: types.maybeNull(types.frozen<IdentifyingAccessTokenDecorated>()),
  })
  .actions(self => ({
    setTyped: <K extends keyof typeof self>(prop: K, value: typeof self[K]) => {
      self[prop] = value;
    },
  }))
  .views(self => ({
    get healthcareSite() {
      if (!self.identifyingAccessToken || !self.identifyingAccessToken.healthcareSites) {
        return null;
      }

      return self.identifyingAccessToken.healthcareSites[0];
    },
  })), {});

// export const node = type.create({});  
