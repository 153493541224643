import { types } from 'mobx-state-tree';

import {
  type as appType,
  node as appNode,
} from 'app/children/patientCapture/domain';


export const type = types.optional(types.model('ApplicationRoutes', {
  app: appType,
}), {});

export const node = type.create({
  app: appNode,
});
