import { WebStyle } from '@clientCommon/styles/web';
import { ContentGateStyleParams } from './types';

const constructCommonStyles = ({ active }: ContentGateStyleParams): WebStyle => ({
  contentGate: {
    position: 'relative',
    height: '100%',
    // backgroundColor: 'cyan',
    display: 'flex',
    flex: '1 1 100%',
  },
  spinner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    // @ts-ignore
    zIndex: '1000',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerImage: {
    height: '11',
    width: '16',
  },
});

export default constructCommonStyles;
