import JSONSchema from 'json-schema';

const isObjectValidBySchema = (obj: object, schema: JSONSchema.JSONSchema7) => {
  return Object.entries(obj).every(([key, value]) => {
    const fieldSchema = schema.properties ? (schema.properties[key] as JSONSchema.JSONSchema7) : null;
    if (!fieldSchema) {
      return true;
    }

    const isFieldRequired = schema.required && schema.required.includes(key);
    if (!isFieldRequired && !value) {
      return true;
    }

    const { valid: isFieldValueValid } = JSONSchema.validate(value, fieldSchema);
    return isFieldValueValid;
  });
};

export default isObjectValidBySchema;
