import React from 'react';

export default function H1({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
  return (
    <h1 className={className} {...props}>
      {children}
    </h1>
  );
}
